// Here you can add other styles

.flexRow {
  display: flex;
  flex-direction: row
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexCenterVertical {
  align-items: center;
}

.flexCenterHorizontal {
  align-items: center;
  justify-content: center;
}

.flexRight {
  margin-left: auto;
  align-items: flex-end;
}

///

.groupLabel {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  span {
    margin-left: 2px;
  }
}

/// Cuby Button Options

.btnOption {
  padding: 10px;
  box-shadow: 1px 1px 1px 1px #888888;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &:active {
    box-shadow: 0.5px 0.5px 0.5px 0.5px #888888;
  }
}
